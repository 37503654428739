import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Image from 'next/image'
import BaseButton from '../../components/Buttons/BaseButton'
import FlexBox from '../../components/FlexBox'
import LandingNav from '../../components/LandingNav'
import { Colors, FontWeight } from '../../constants/theme'

const StyledTitle = styled.h1`
  text-align: center;
  color: ${Colors.primary};
  font-size: 64px;
  font-weight: ${FontWeight.extraBold};
  margin: 0;
  align-self: center;
  justify-self: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 180px;
    line-height: initial;
    margin: 0;
    align-self: flex-start;
  }
`

const StyledSubtitle = styled.h2`
  color: ${Colors.darkBlue};
  font-size: 36px;
  font-weight: ${FontWeight.bold};
  margin: 10px 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 72px;
  }
`

const StyledDesc = styled.span`
  color: ${Colors.text};
  font-size: 16px;
  font-weight: ${FontWeight.medium};
  max-width: 245px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 28px;
    text-align: left;
    max-width: 366px;
  }
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`

const StyledMobileImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 260px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`

const StyledImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
  }
`

const StyledDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    justify-content: center;
    align-items: flex-start;
  }
`

const Error404 = () => {
  return (
    <>
      <LandingNav />
      <FlexBox center height="calc(100vh - 70px)" sx={{ marginTop: '70px' }}>
        <Container disableGutters maxWidth="lg" style={{ height: '100%' }}>
          <BodyContainer>
            <StyledDescContainer>
              <StyledTitle>404</StyledTitle>
              <StyledMobileImageContainer>
                <Image
                  src="/images/error_404_mobile.png"
                  alt="error 404"
                  fill
                  sizes="100vw"
                  style={{ objectFit: 'contain' }}
                  priority
                />
              </StyledMobileImageContainer>
              <StyledSubtitle>Oopps!</StyledSubtitle>
              <StyledDesc>No podemos encontrar la pagina que buscas</StyledDesc>
              <Box my={2} maxWidth={220}>
                <BaseButton href="/" variant="contained" rounded fullWidth>
                  Regresar a inicio
                </BaseButton>
              </Box>
            </StyledDescContainer>
            <StyledImageContainer>
              <Image
                src="/images/error_404.png"
                alt="error 404"
                fill
                sizes="50vw"
                style={{ objectFit: 'contain' }}
                priority
              />
            </StyledImageContainer>
          </BodyContainer>
        </Container>
      </FlexBox>
    </>
  )
}

export default Error404
